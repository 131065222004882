<template>
  <div class="userShow">
    <Nav></Nav>
    <div class="user-con desktop-present">
      <div class="user-title">
        <div class="title-icon"></div>
        <div class="title-text">首页 > 摄影师</div>
      </div>
      <!-- 签约记者 -->
      <div class="user-list">
        <div class="user-thr">
          <!-- <div class="ft-shadow"></div> -->
          <div class="user-name">签约摄影师</div>
          <div class="user-en">Contract Photographers</div>
          <div class="user-text">按姓名首字母排序</div>
          <!-- <div class="more" @click="onMorereporter(3)">更多>></div> -->
        </div>
        <div class="user-detail">
          <div
            class="user-reporter"
            v-for="k in userList_registed"
            :key="'nav_' + k.id"
            @click="onUserDetail(k.id, 3)"
          >
            <div
              class="reporter-img"
              :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
              @click="onUserDetail(k.id, 3)"
            ></div>
            <div class="reporter-con">
              <div class="name" @click="onUserDetail(k.id, 3)">
                ---- {{ k.name }} ----
              </div>
              <div class="en-name">{{ k.nameEn }}</div>
              <!-- <a :href="'/userShowDetail.html?id='+ k.id + '&code=3'"></a> -->
              <!-- <div class="detail" @click="onUserDetail(k.id, 3)">
                {{ k.detail }}
              </div> -->
            </div>
            <div class="user-introl">
              <div class="name">---- {{ k.name }} ----</div>
              <div class="en-name">{{ k.nameEn }}</div>
              <div class="text" v-html="k.detail"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 摄影记者 -->
      <!-- <div class="user-list">
        <div class="user-sec">
          <div class="user-name">摄影记者</div>
          <div class="user-en">Press Photographers</div>
          <div class="user-text">按姓名首字母排序</div>
          <div class="more" @click="onMorereporter(3)">更多>></div>
        </div>
        <div class="user-detail">
          <div
            class="user-reporter"
            v-for="k in userList_registed"
            :key="'nav_' + k.id"
          >
            <div
              class="reporter-img"
              :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
              @click="onUserDetail(k.id, 3)"
            ></div>
            <div class="reporter-con">
              <div class="name" @click="onUserDetail(k.id, 3)">
                ---- {{ k.name }} ----
              </div>
              <div class="en-name">{{ k.nameEn }}</div>
            </div>
            <div class="user-introl">
              <div class="name">---- {{ k.name }} ----</div>
              <div class="en-name">{{ k.nameEn }}</div>
              <div class="text" v-html="k.detail"></div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- 特约摄影 -->
      <!-- <div class="user-list">
        <div class="user-title">
          <div class="ft-shadow"></div>
          <div class="user-name">特约摄影师</div>
        </div>
        <div class="user-detail">
        
          <div
            class="user-reporter"
            v-for="k in userList_master"
            :key="'nav_' + k.id"
          >
            <div
              class="reporter-img"
              :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
              @click="onUserDetail(k.id, 1)"
            ></div>
            <div class="reporter-con">
              <div class="name" @click="onUserDetail(k.id, 1)">
                -- {{ k.name }} --
              </div>
            </div>
          </div>
        </div>
        <div class="more" @click="onMorereporter(1)">更多...</div>
      </div> -->
    </div>
    <div class="user-con mobile-present">
      <!-- 签约记者 -->
      <div class="user-list">
        <div class="user-thr">
          <!-- <div class="ft-shadow"></div> -->
          <div class="user-name">签约摄影师</div>
          <div class="user-en">Contract Photographers</div>
          <div class="user-text">按姓名首字母排序</div>
          <!-- <div class="more" @click="onMorereporter(3)">更多>></div> -->
        </div>
        <div class="user-detail">
          <div
            class="user-reporter"
            v-for="k in userList_registed"
            :key="'nav_' + k.id"
            @click="onUserDetail(k.id, 3)"
          >
            <div
              class="reporter-img"
              :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
              @click="onUserDetail(k.id, 3)"
            ></div>
            <div class="reporter-con">
              <div class="name" @click="onUserDetail(k.id, 3)">
                ---- {{ k.name }} ----
              </div>
              <div class="en-name">{{ k.nameEn }}</div>
              <!-- <a :href="'/userShowDetail.html?id='+ k.id + '&code=3'"></a> -->
              <!-- <div class="detail" @click="onUserDetail(k.id, 3)">
                {{ k.detail }}
              </div> -->
            </div>
            <div class="user-introl">
              <div class="name">---- {{ k.name }} ----</div>
              <div class="en-name">{{ k.nameEn }}</div>
              <div class="text" v-html="k.detail"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from "@/api/RD";
import Nav from "@/components/nav.vue";
import Bottom from "@/components/bottom.vue";

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      userList_master: [], // 摄影列表
      userList_reporter: [], // 摄影列表
      userList_registed: [], // 签约摄影师列表
    };
  },
  created() {
    this.reload();
  },
  methods: {
    reload() {
      RD.userShow()
        .tops()
        .then((k) => {
          k.forEach((i) => {
            i.users.forEach((h) => {
              h.imgURL = RD.userShow().id(h.id).headPic_URL();
            });
          });
          for (var subList of k) {
            // if (subList.code == 1) {
            //   this.userList_master = subList.users;
            // }
            // if (subList.code == 2) {
            //   this.userList_reporter = subList.users;
            // }
            if (subList.code == 1) {
              this.userList_registed = subList.users;
            }
          }
        });
    },
    // 更多
    onMorereporter(code) {
      this.$router.push({
        path: "/userShowList.html",
        query: { code: code },
      });
    },
    onUserDetail(id, code) {
      this.$router.push({
        path: "/userShowDetail.html",
        query: { id: id, code: code },
      });
    },
  },
};
</script>

<style scoped lang="less">
.userShow {
  height: auto;
  width: 100%;
  .user-con.desktop-present {
    width: 120rem;
    margin: 0 auto;
    .user-title {
      text-align: left;
      display: flex;
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      border-bottom: 1px solid #aeaeae;
      .title-icon {
        width: 1.5rem;
        height: 3rem;
        background: no-repeat url(../assets/loc.png);
        background-size: contain;
        margin-top: 13px;
      }
      .title-text {
        margin-left: 20px;
      }
    }
    .user-list {
      .user-title {
        text-align: left;
        height: 6rem;
        margin-bottom: 3rem;
        display: flex;
        .ft-shadow {
          margin-top: 1rem;
          //   background: no-repeat url(../assets/carame.png);
          background-size: cover;
          width: 5rem;
          height: 5rem;
        }
        .user-name {
          font-size: 1.8rem;
          height: 7rem;
          line-height: 7rem;
        }
      }
      .user-sec {
        text-align: left;
        height: 6rem;
        display: flex;
        margin-bottom: 3rem;
        position: relative;
        .ft-shadow {
          margin-top: 1rem;
          //   background: no-repeat url(../assets/carame.png);
          background-size: cover;
          width: 5rem;
          height: 5rem;
        }
        .user-name {
          font-size: 1.8rem;
          height: 7rem;
          line-height: 7rem;
        }
        .user-name {
          font-size: 3.6rem;
          height: 7rem;
          line-height: 7rem;
        }
        .user-text {
          position: absolute;
          left: 45rem;
          top: 20px;
          vertical-align: bottom;
          margin-left: 10px;
          font-size: 22px;
          color: #b5b5b5;
          font-weight: lighter;
          font-family: "Microsoft Yahei", "Simsun";
        }
        .user-en {
          position: absolute;
          left: 19rem;
          top: 20px;
          font-size: 22px;
          vertical-align: bottom;
          margin-left: 10px;
          color: #b5b5b5;
          font-weight: lighter;
          font-family: "Microsoft Yahei", "Simsun";
        }
        .more {
          color: #aeaeae;
          font-size: 18px;
          position: absolute;
          right: 0px;
          top: 20px;
          cursor: pointer;
        }
      }
      .user-thr {
        text-align: left;
        height: 6rem;
        margin: 2rem 0rem;
        display: flex;
        position: relative;
        .ft-shadow {
          margin-top: 1rem;
          background-size: cover;
          width: 5rem;
          height: 5rem;
        }
        .user-name {
          font-size: 3.6rem;
          height: 7rem;
          line-height: 7rem;
        }
        .user-text {
          position: absolute;
          left: 45rem;
          top: 20px;
          vertical-align: bottom;
          margin-left: 10px;
          font-size: 22px;
          color: #b5b5b5;
          font-weight: lighter;
          font-family: "Microsoft Yahei", "Simsun";
        }
        .user-en {
          position: absolute;
          left: 19rem;
          top: 20px;
          font-size: 22px;
          vertical-align: bottom;
          margin-left: 10px;
          color: #b5b5b5;
          font-weight: lighter;
          font-family: "Microsoft Yahei", "Simsun";
        }
        .more {
          color: #aeaeae;
          font-size: 18px;
          position: absolute;
          right: 0px;
          top: 20px;
          cursor: pointer;
        }
      }
      .user-detail {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        overflow: hidden;
        position: relative;
        .user-reporter {
          width: 29rem;
          height: 34rem;
          overflow: hidden;
          margin-bottom: 5rem;
          margin-right: 1rem;
          position: relative;
          background-color: #fff;
          cursor: pointer;
          .reporter-img {
            width: 15rem;
            height: 15rem;
            margin: 50px auto 40px;
            background: no-repeat 50% / cover;
            border-radius: 50%;
            overflow: hidden;
            cursor: pointer;
          }
          .reporter-con {
            height: 3rem;
            text-align: center;
            .name {
              color: #aeaeae;
              font-size: 22px;
              text-align: center;
              height: 50px;
              line-height: 50px;
            }
            .en-name {
              color: #aeaeae;
              text-align: center;
            }
          }
          .user-introl {
            width: 0px;
            height: 0px;
            opacity: 0;
            transition: all 200ms ease-in-out;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .name {
              color: #fff;
              font-size: 22px;
              text-align: center;
              height: 50px;
              line-height: 50px;
              margin-top: 80px;
            }
            .en-name {
              color: white;
              display: block;
              text-align: center;
              margin-top: 10px;
            }
            .text {
              display: block;
              color: white;
              height: 133px;
              margin-top: 40px;
              width: 70%;
              text-align: center;
              margin-left: 15%;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 6;
              -webkit-box-orient: vertical;
              line-height: 20px;
            }
          }
        }
        .user-reporter:hover {
          .user-introl {
            opacity: 1;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            transition: all 0.3s linear;
          }
        }
      }
      // .more {
      //   height: 2.5rem;
      //   line-height: 2.5rem;
      //   text-align: right;
      //   font-size: 1.3rem;
      //   margin-right: 3rem;
      //   margin-top: 2rem;
      //   font-weight: bold;
      //   cursor: pointer;
      // }
    }
  }
  .user-con.mobile-present {
    width: 750px;
    margin: 0 auto;
    .user-list {
      min-height: calc(100vh - 369px);
      .user-title,
      .user-sec,
      .user-thr {
        text-align: left;
        height: 40px;
        margin-bottom: 20px;
        display: flex;
        .ft-shadow {
          margin-top: 1rem;
          //   background: no-repeat url(../assets/carame.png);
          background-size: cover;
          width: 40px;
          height: 40px;
        }
        .user-name {
          font-size: 18px;
          height: 55px;
          line-height: 55px;
        }
        .user-en {
          line-height: 55px;
          margin-left: 10px;
        }
        .user-text {
          line-height: 55px;
          margin-left: 10px;
        }
      }
      .user-detail {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        overflow: hidden;
        .user-reporter {
          width: 108px;
          height: 145px;
          overflow: hidden;
          margin-bottom: 2rem;
          margin-right: 2rem;
          position: relative;
          background-color: #f5f5f5;
          .reporter-img {
            width: 91px;
            height: 91px;
            margin: 0 auto;
            background: no-repeat 50% / cover;
            border-radius: 50%;
            overflow: hidden;
            cursor: pointer;
          }
          .reporter-con {
            height: 60px;
            text-align: center;
            .name {
              text-align: center;
              height: 20px;
              font-size: 14px;
              font-weight: bold;
              margin-top: 10px;
              cursor: pointer;
            }
            .en-name {
              height: 30px;
            }
          }
        }
      }
      .more {
        height: 2.5rem;
        line-height: 2.5rem;
        text-align: right;
        font-size: 1.3rem;
        margin-right: 3rem;
        margin-top: 2rem;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}
</style>
